import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

import Produits from './index';

const PageProduitsArchived: FC<PageProps & EspaceProps & UserProps> = props => (
  <Produits {...props} pageContext={{ tab: 7 }} />
);

export default requireEspace(PageProduitsArchived);
